import {
    DevicesCountsByStatuses,
    DocsCountsStat,
    DocumentsStatDto,
    OperatorsCashBoxesDto,
} from '../api/dashBoard/dashBoardClient.typings';
import { CashBoxesStatDto } from '../api/monitoring/monitoringClient.typings';
import { DashBoardTechAction, DashBoardTechActionType } from '../actions/dashBoardTechActions';
import { TechChartsFilesMap } from '../../components/DashboardWitgets/Common/ChartsTyping';

export interface DashBoardTechState {
    documentsStat: DocumentsStatDto[];
    cashBoxesStat: CashBoxesStatDto;
    operatorsCashBoxesStat: OperatorsCashBoxesDto[];
    docsCountsStat: DocsCountsStat;
    deviceCountsStat: DevicesCountsByStatuses;

    isLoadingDocsCountsStat: boolean;
    isLoadingOperatorsCashBoxesStat: boolean;
    isLoadingCashBoxesStat: boolean;
    isLoadingDocData: boolean;
    isLoadingDeviceCountsStat: boolean;
}

const initialState: DashBoardTechState = {
    documentsStat: [],
    cashBoxesStat: null,
    operatorsCashBoxesStat: [],
    docsCountsStat: null,
    deviceCountsStat: null,

    isLoadingDocData: false,
    isLoadingCashBoxesStat: false,
    isLoadingOperatorsCashBoxesStat: false,
    isLoadingDocsCountsStat: false,
    isLoadingDeviceCountsStat: false,
};

export function dashBoardTechReducer(
    state: DashBoardTechState = initialState,
    action: DashBoardTechAction,
): DashBoardTechState {
    let newState: DashBoardTechState;
    switch (action.type) {
        case DashBoardTechActionType.OPEN_DASHBOARD_TECH_PAGE:
            return {
                ...state,
                isLoadingDocData: true,
                isLoadingCashBoxesStat: true,
                isLoadingOperatorsCashBoxesStat: true,
                isLoadingDocsCountsStat: true,
                isLoadingDeviceCountsStat: true,
            };

        case DashBoardTechActionType.FETCH_DOCUMENTS_STAT_SUCCESS:
            return {
                ...state,
                documentsStat: action.payload,
                isLoadingDocData: false,
            };

        case DashBoardTechActionType.FETCH_DOCUMENTS_STAT_ERROR:
            return {
                ...state,
                isLoadingDocData: false,
            };

        case DashBoardTechActionType.FETCH_CASH_BOXES_STAT_SUCCESS:
            return {
                ...state,
                cashBoxesStat: action.payload,
                isLoadingCashBoxesStat: false,
            };

        case DashBoardTechActionType.FETCH_CASH_BOXES_STAT_ERROR:
            return {
                ...state,
                isLoadingCashBoxesStat: false,
            };

        case DashBoardTechActionType.FETCH_OPERATORS_CASH_BOXES_SUCCESS:
            return {
                ...state,
                operatorsCashBoxesStat: action.payload,
                isLoadingOperatorsCashBoxesStat: false,
            };

        case DashBoardTechActionType.FETCH_OPERATORS_CASH_BOXES_ERROR:
            return {
                ...state,
                isLoadingOperatorsCashBoxesStat: false,
            };

        case DashBoardTechActionType.FETCH_DOCS_COUNTS_SUCCESS:
            return {
                ...state,
                docsCountsStat: action.payload,
                isLoadingDocsCountsStat: false,
            };

        case DashBoardTechActionType.FETCH_DOCS_COUNTS_ERROR:
            return {
                ...state,
                isLoadingDocsCountsStat: false,
            };

        case DashBoardTechActionType.FETCH_DEVICE_COUNTS_SUCCESS:
            return {
                ...state,
                deviceCountsStat: action.payload,
                isLoadingDeviceCountsStat: false,
            };

        case DashBoardTechActionType.FETCH_DEVICE_COUNTS_ERROR:
            return {
                ...state,
                isLoadingDeviceCountsStat: false,
            };

        case DashBoardTechActionType.DOWNLOAD_EXCEL:
            newState = { ...state };
            newState[TechChartsFilesMap[action.payload.dataType].isLoadingKey] = true;
            return newState;

        case DashBoardTechActionType.DOWNLOAD_EXCEL_SUCCESS:
            newState = { ...state };
            newState[TechChartsFilesMap[action.payload].isLoadingKey] = false;
            return newState;

        case DashBoardTechActionType.DOWNLOAD_EXCEL_ERROR:
            newState = { ...state };
            newState[TechChartsFilesMap[action.payload.dataType].isLoadingKey] = false;
            return newState;

        case DashBoardTechActionType.EXIT_DASHBOARD_TECH_PAGE:
            return {
                ...initialState,
            };
    }

    return state;
}
